import guide_0 from '../static/guide-0.png'
import guide_1 from '../static/guide-1.png'
import guide_2 from '../static/guide-2.png'
import guide_3 from '../static/guide-3.png'
import guide_4 from '../static/guide-4.png'
import guide_5 from '../static/guide-5.png'
import '../css/guide.css'
import { useNavigate } from "react-router-dom";


function Section({ title, text, image }) {
    return (
        <div className='section-div'>
            <div className='section-image'>
                <img className='image' src={image} alt={title}/>
            </div>
            <div className='section-title'>
                {title}
            </div>
            <div className='section-text'>
                {text}
            </div>
        </div>
    )
}

export function ScanGuide() {

    const navigate = useNavigate();
    const buttonOnclick = async (e) => {
        e.preventDefault()    
        navigate("/qrcode");
    }

    return (
        <div id='guide-div-outer'>
            <div id='guide-div-inner'>
                <div>
                    <div id='title'>
                        Scan Guide
                    </div>
                    <div id='sub-title'>
                        Follow this guide to take a good scan.
                    </div>
                </div>
                <div>
                    <Section
                        title="Wear Tight Clothing"
                        text="ShapeScale measures what it sees. To get accurate measurements, wear compression clothing or tight underwear."
                        image={guide_0}
                    />
                    <Section
                        title="Tie Up Hair"
                        text="Make sure that your hair does not cover your neck and face."
                        image={guide_1}
                    />
                    <Section
                        title="Have a great posture"
                        text="The better your posture, the higher the score will be."
                        image={guide_2}
                    />
                    <Section
                        title="Make a fist"
                        text="Make a fist with your hands and keep them 2 inches away from hips."
                        image={guide_3}
                    />
                    <Section
                        title="Stay as still as possible"
                        text="To ensure an accurate scan, it is important to breathe shallow and remain completely still. Fixate on an imaginary point in front of you and do not watch the rotating device."
                        image={guide_4}
                    />
                    <Section
                        title="Duration"
                        text="The scan will take 1 minute. The device will inform you which part of your body is beingscanned and when you can step off."
                        image={guide_5}
                    />
                </div>
                <div id='button'>
                    <button className='default-button' onClick={buttonOnclick}>
                        Start scan now
                    </button>
                </div>
                <div id='link-div'>
                    <a id='link'
                        href='https://www.youtube.com/watch?v=MQUJbVRBZtU&list=PLfyPOnv9lAH2nQQA892UGKDODtLySyu56&index=2&ab_channel=ShapeScale'
                        target='_blank'
                        rel="noreferrer"
                    >
                        See a video of a scan
                    </a>
                </div>
            </div>
        </div>
    )
}


export default ScanGuide