import shapescale_logo from '../static/shapescale-logo-footer.png'
import x from '../static/x.png'
import facebook from '../static/facebook.png'
import instagram from '../static/instagram.png'
import '../css/footer.css'

function Section({ text, link }) {
    return (
        <div className='footer-text-links-div'>
            <a className='footer-text-links-a'
                target='_blank'
                href={link}
                rel="noreferrer"
            >
                {text}
            </a>
        </div>
    )
}

function Icon({ img, link, name }) {
    return (
        <div className="social-links-divs">
            <a className="social-links-a-tags"
                target='_blank'
                href={link}
                rel="noreferrer"
            >
                <img src={img} alt={link} id={name}>

                </img>
            </a>
        </div>
    )
}

export function Footer() {
    return (
        <div id="footer-outer">
            <div id="shapescale-logo">
                <Icon img={shapescale_logo} name="shapescale" link="https://shapescale.com/" />
            </div>
            <div id="social-links-div">
                <Icon
                    img={instagram} name="instagram"
                    link="https://www.instagram.com/shapescale/"
                />
                <Icon
                    img={x} name="x" link="https://twitter.com/shapescale"
                />
                <Icon
                    img={facebook} name="facebook"
                    link="https://www.facebook.com/shapescale/"
                />
            </div>
            <div id="footer-text-links-div">
                <Section text="TECH" link="https://shapescale.com/tech.html" />
                <Section text="BUSINESS" link="https://business.shapescale.com/" />
                <Section text="SUPPORT" link="https://support.shapescale.com/" />
                <Section text="ABOUT US" link="https://shapescale.com/company.html" />
                <Section text="TERMS" link="https://shapescale.com/terms.html" />
            </div>
            <div id="address-div">
                Shape Labs Inc. - 179 11th St - San Francisco - California - +1-833-BYSHAPE
            </div>
        </div>
    )
}


export default Footer