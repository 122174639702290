import processsing_0 from '../static/processing-0.png'
import processsing_1 from '../static/processing-1.png'
import '../css/processing.css'
import { Footer } from '../Pages/Footer'
import React from 'react'

export function Processing() {
    return (
        <div>
            <div id="processing-div-outer">
                <div id="processing-div-inner">
                    <div>
                        <img
                            className='processing-image' src={processsing_0}
                            alt="scale_head"
                        >
                        </img>
                    </div>
                    <div className='processing-titles'>
                        Upload
                    </div>
                    <div className='processing-text'>
                        The Head light indicates the progress of the scan upload,
                        which should take only a few minutes. Once the LED ring is complete,
                        your scan has been uploaded.
                    </div>
                    <div>
                        <img
                            className='processing-image' src={processsing_1}
                            alt="mesh_body"
                        >
                        </img>
                    </div>
                    <div className='processing-titles'>
                        Processing
                    </div>
                    <div className='processing-text'>
                        Once uploaded, it will take about 5 minutes to process your scan.
                        You will receive an email once your scan has finished processing.
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}


export default Processing