import 'semantic-ui-css/semantic.min.css'
import './App.css';
import {Intro} from './Pages/Intro';

import React from 'react';
import './index.css';

import { Scan, loader as scanLoader } from './Pages/Scan'
import { ScanGuide } from './Pages/ScanGuide'
import { Qrcode } from './Pages/Qrcode'
import { Processing } from './Pages/Processing'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const appUrls = {
  local: 'http://localhost:3000',
  development: 'https://kiosk.dahm7eir.shapescale.com',
  staging: 'https://kiosk.pup.shapescale.com',
  production: 'https://kiosk.shapescale.com'
}

const apiUrls = {
  local: 'http://localhost:8000/api',
  development: 'https://api.dahm7eir.shapescale.com/api',
  staging: 'https://api.pup.shapescale.com/api',
  production: 'https://api.prod.shapescale.com/api'
}

const apiUrl = apiUrls[process.env.REACT_APP_ENVIRONMENT]
const appUrl = appUrls[process.env.REACT_APP_ENVIRONMENT]
export const URLS = {
  app: appUrl,
  qrcode: apiUrl + '/v1/kiosk_scan_qrcode',
  scan: (scanId) => {return apiUrl + `/v1/kiosk_scan/${scanId}`},
}


const router = createBrowserRouter([
  {
    path: "/",
    element: <Intro />,
  },
  {
    path: "scans/:scanId",
    element: <Scan/>,
    loader: scanLoader
  },
  {
    path: "guide",
    element: <ScanGuide/>,
  },
  {
    path: "qrcode",
    element: <Qrcode/>,
  },
  {
    path: "processing",
    element: <Processing/>,
  }
]);


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <RouterProvider router={router}/>
      </header>
      {/* <ScrollToTop/> */}
      
    </div>
  );
}

export default App;
