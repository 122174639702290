import QRCode from 'qrcode'
import '../css/qrcode.css'
import { URLS } from '../App'
import { useEffect, useState } from 'react'
import qr_code_png from '../static/qr-code-0.png'
import { useNavigate } from "react-router-dom";


const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const jwtExpiresIn = (decodedJwt) => {
    return decodedJwt.exp - (Date.now() / 1000)
}

const shouldRefreshJwt = (decodedJwt) => {
    const expiresInSeconds = jwtExpiresIn(decodedJwt)
    return expiresInSeconds < 60
}


let fetching = false

export function Qrcode() {
    const [image, setImage] = useState();
    const navigate = useNavigate();
    const fetchJWT = async () => {
        if (fetching === true) {
            return
        }
        fetching = true
        let jwt = localStorage.getItem("jwt")

        if (jwt === null) {
            navigate("/");
        } else {
            const decodedJwt = parseJwt(jwt)
            if (shouldRefreshJwt(decodedJwt)) {
                const data = { email: decodedJwt.sub }
                const res = await fetch(
                    URLS.qrcode, {
                    method: "POST",
                    body: JSON.stringify(data),
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
                )
                const jsonRes = await res.json()
                jwt = jsonRes.jwt
            }
        }
        const opts = {
            margin: 0,
            color: {
                dark: "#000000",
                light: "#EBEBEB"
            }
        }

        try {
            const url = await QRCode.toDataURL(jwt, opts)
            setImage(url)
        } catch (err) {
            console.error(err)
        }
    }
    useEffect(() => { fetchJWT() })

    const buttonOnclick = async (e) => {
        e.preventDefault()
        navigate("/processing");
    }

    return (
        <div id='qrcode-div-outer'>
            <div id='qrcode-div-inner'>
                <div >
                    <img id="qrcode-image" src={image} alt="qr_code">
                    </img>
                </div>
                <div id="text">
                    Position the QR code 1 foot away from the sensor and ensure 
                    your screen is at its maximum brightest to start the scan.
                </div>
                <div id="image">
                    <img src={qr_code_png} alt="qrcode_description">

                    </img>
                </div>
                <div id="button">
                    <button className='default-button' onClick={buttonOnclick}>
                        Done
                    </button>
                </div>
            </div>
        </div>
    )
}


export default Qrcode