import React, { useState } from "react"
import { isBrowser, isMobile } from 'react-device-detect';
import intro_0 from "../static/intro-0.png"
import intro_1 from "../static/intro-1.png"
import intro_2 from "../static/intro-2.png"
import intro_3 from "../static/intro-3.png"
import intro_4 from "../static/intro-4.png"
import { Input } from 'semantic-ui-react'
import { URLS } from '../App'
import { useNavigate } from "react-router-dom";
import '../css/intro.css';


function Circle({ pageNumber, currentPage, setPage }) {
    let className
    if (pageNumber === currentPage) {
        className = 'dot dot-selected'
    } else {
        className = 'dot'
    }

    let onclick = null
    if (isBrowser) {
        onclick = () => {
            setPage(pageNumber)
        }
    }


    return (
        <span className={className} onClick={onclick}></span>
    )
}

function ImageSection({ image, text, page }) {
    /*
    Only change the id after the image loads. Because between the first and
    second image, the css is applied to the old image before the new image is
    downloaded, and it looks funky.
    */
    const onLoad = (e) => {
        e.target.id = `image-${page}`
    }

    return (
        <div>
            <img
                className="intro-image"
                src={image} alt="Intro"
                onLoad={onLoad}
            >
            </img>
            <div className="intro-text">
                {text}
            </div>
        </div>
    )
}



async function updatePage(page, setPage) {
    let newPage
    if (page === 4) {
        newPage = 0
    } else {
        newPage = page + 1
    }
    setPage(newPage)
}


export function Intro() {

    const [page, setPage] = useState(0);
    const imageSectionMap = {
        0: { image: intro_0, text: "Know how your body changes" },
        1: { image: intro_1, text: "Capture the way you look" },
        2: { image: intro_2, text: "Instantly see your progress" },
        3: { image: intro_3, text: "See changes in the areas you care about" },
        4: { image: intro_4, text: "Know what to focus on with scores and insights" }
    }
    const imageSectionArgs = imageSectionMap[page]

    if (isMobile) {
        setTimeout(updatePage, 5000, page, setPage)
    }
    const [email, setEmail] = useState('');
    const handleChange = event => {
        setEmail(event.target.value);

    };

    const [errMessage, setErrMessage] = useState();

    const navigate = useNavigate();
    const buttonOnclick = async (e) => {
        e.preventDefault()
        const data = { 'email': email }
        try {
            const res = await fetch(
                URLS.qrcode, {
                method: "POST",
                body: JSON.stringify(data),
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                }
            }
            )
            if (res.status === 200) {
                const jsonRes = await res.json()
                localStorage.setItem("jwt", jsonRes.jwt)
                navigate("/guide");
            } else if (res.status === 409) {
                setErrMessage("User with this email already exists.")
            } else if (res.status === 400) {
                const jsonRes = await res.json()
                if ('email' in jsonRes) {
                    const emailRes = jsonRes.email[0]
                    if (emailRes === "Enter a valid email address.") {
                        setErrMessage("Invalid email address.")
                    } else if (emailRes === "This field may not be blank.") {
                        setErrMessage("Can't be blank.")
                    } else {
                        setErrMessage("Something went wrong.")
                    }
                }

            } else {
                setErrMessage("Something went wrong.")
            }
        } catch (error) {
        }
    }

    return (
        <div>
            <div id="top-section">
                <div className="title">
                    SHAPESCALE
                </div>
                <ImageSection image={imageSectionArgs.image} text={imageSectionArgs.text} page={page} />
                <div id="ciclesDiv">
                    <Circle pageNumber={0} currentPage={page} setPage={setPage} />
                    <Circle pageNumber={1} currentPage={page} setPage={setPage} />
                    <Circle pageNumber={2} currentPage={page} setPage={setPage} />
                    <Circle pageNumber={3} currentPage={page} setPage={setPage} />
                    <Circle pageNumber={4} currentPage={page} setPage={setPage} />
                </div>
            </div>
            <div id="bottom-section">
                <div id="bottom-section-1">
                    <div id="enter-email">
                        Enter your email to start your scan
                    </div>
                </div>
                <div id="bottom-section-2">
                    <Input
                        id="text-input"
                        type="email"
                        icon="user circle"
                        placeholder="Email"
                        iconPosition='left'
                        onChange={handleChange}
                    />
                </div>
                <div id="bottom-section-3">
                    <button className="default-button" onClick={buttonOnclick}>
                        Start my scan
                    </button>
                    {errMessage}
                </div>
            </div>
        </div>
    )
}


export default Intro
